import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { isEqual, noop } from 'lodash-es';
import { BarcodeReader } from 'components/Barcode/BarcodeReader';
import { Stepper } from 'ui/theme/stepper/Stepper';
import { CenterSpinner } from 'components/CenterSpinner';
import { Colour } from 'ui/Colour';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER, UNDEFINED } from 'constants/semanticConstants';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { GroupType } from 'constants/GroupType';
import useEnterResult from './hooks/useEnterResult';
import { EnterResult } from './EnterResult';

const UIState = {
  SCAN_FIRST_STEP: 0,
  RESULT_SECOND_STEP: 1,
};

const ALL_STEPS = ['result.step.scan_test', 'result.step.enter_result'];
const ALL_CO2_STEPS = [
  'result.step.scan_certificate',
  'result.step.enter_result',
];

export const Result = () => {
  const {
    current: { groupName },
  } = useOrganization();

  const [uiState, setUIState] = useState(UIState.SCAN_FIRST_STEP);
  const [testcode, setTestcode] = useState(UNDEFINED);

  const resetState = useCallback(() => {
    setUIState(UIState.SCAN_FIRST_STEP);
    setTestcode(UNDEFINED);
  }, []);

  const { mutate: mutateByEnteringResults, isLoading } = useEnterResult({
    onSuccess: resetState,
    groupName,
  });

  const onScanSuccess = useCallback((data) => {
    if (!data) {
      return;
    }

    setTestcode(data);
    setUIState(UIState.RESULT_SECOND_STEP);
  }, []);

  const onRestartClick = useCallback(() => {
    setUIState(UIState.SCAN_FIRST_STEP);
  }, []);

  const submitResult = useCallback(
    ({ testCode, result, notifyCustomer, sendHealthAuthorityResult }) => {
      if (isEqual(groupName, GroupType.COV19)) {
        const payload = {
          test_code: testCode,
          result,
          suppress_customer_notification: !notifyCustomer,
          suppress_health_authority_notification: !sendHealthAuthorityResult,
        };
        mutateByEnteringResults({
          testCode,
          payload,
        });
      } else {
        const payload = {
          test_code: testCode,
          result,
        };
        mutateByEnteringResults({
          testCode,
          payload,
        });
      }
    },
    [mutateByEnteringResults, groupName],
  );

  return (
    <Box p={2}>
      <Stepper
        steps={
          isEqual(groupName, GroupType.CO2) ||
          isEqual(groupName, GroupType.HINTERLAND)
            ? ALL_CO2_STEPS
            : ALL_STEPS
        }
        activeStep={uiState}
      />

      {isLoading ? (
        <CenterSpinner />
      ) : (
        <>
          {isEqual(UIState.SCAN_FIRST_STEP, uiState) ? (
            <>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                m={2}
              >
                <Typography variant="h6" align="center">
                  <Translate
                    text="scan.result.title"
                    values={{
                      value:
                        isEqual(groupName, GroupType.CO2) ||
                        isEqual(groupName, GroupType.HINTERLAND)
                          ? 'Zertifikat'
                          : 'Test',
                    }}
                  />
                </Typography>
              </Box>

              <BarcodeReader
                onScanSuccess={onScanSuccess}
                onScanError={noop}
                variantColor={Colour.orange}
              />
            </>
          ) : (
            SKIP_RENDER
          )}

          {isEqual(UIState.RESULT_SECOND_STEP, uiState) ? (
            <EnterResult
              onFinished={submitResult}
              scannedValue={testcode}
              onRestartClick={onRestartClick}
              groupName={groupName}
            />
          ) : (
            SKIP_RENDER
          )}
        </>
      )}
    </Box>
  );
};
