import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { parseInt, isEqual } from 'lodash-es';
import { Controller, useForm } from 'react-hook-form';
import { Translate } from 'i18n/Translate';
import { ExpirationDates } from 'constants/ExpirationDate';
import {
  EMPTY_ARRAY,
  EMPTY_STRING,
  EMPTY_OBJECT,
} from 'constants/semanticConstants';
import { Roles, Co2Roles } from 'constants/Roles';
import { getCo2RoleName, getRoleName } from 'roles';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { GroupType } from 'constants/GroupType';
import { getExpirationDate } from './getExpirationDate';

const ALL_EXPIRATION = Object.values(ExpirationDates);

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const DEFAULT_VALUES = {
  tokenName: EMPTY_STRING,
  roles: EMPTY_ARRAY,
  expireDate: ExpirationDates.SEVEN_DAYS,
};

export const PersonalAccessTokenDialog = ({
  accountRoles = EMPTY_ARRAY,
  isOpen,
  isPending,
  onClose,
  onSubmit,
  groupName,
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const currentDate = new Date();

  const initiallyRoles = useMemo(
    () =>
      Object.values(
        isEqual(groupName, GroupType.CO2) ||
          isEqual(groupName, GroupType.HINTERLAND)
          ? Co2Roles
          : Roles,
      ).reduce(
        (roles, role) => ({
          ...roles,
          [role]: accountRoles.includes(role),
        }),
        EMPTY_OBJECT,
      ),
    [accountRoles, groupName],
  );

  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  // Resets the form whenever we open/close the dialog
  useEffect(() => reset(DEFAULT_VALUES), [reset, isOpen]);

  const computeExpirationDate = (value) => {
    const numberOfDays = parseInt(value.split(' ')[0]);
    return new Date(
      new Date(currentDate).setDate(currentDate.getDate() + numberOfDays),
    ).toDateString();
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
    >
      <BackdropSpinner isOpen={isPending} />

      {/* Title of the dialog */}
      <DialogTitle id="form-dialog-title">
        <Translate text="profile.personalAccessToken.add.dialog.title" />
      </DialogTitle>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Translate text="profile.personalAccessToken.add.dialog.description" />

          {/* Name of the account */}
          <Controller
            control={control}
            name="tokenName"
            rules={{
              required: formatMessage({
                id: 'profile.personalAccessToken.add.dialog.name.required',
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                autoFocus
                disabled={isPending}
                error={!!error}
                fullWidth
                helperText={error?.message}
                InputLabelProps={{ htmlFor: 'tokenName' }}
                id="tokenName"
                label={
                  <Translate text="profile.personalAccessToken.add.dialog.tokenName" />
                }
                margin="dense"
                variant="outlined"
                {...field}
              />
            )}
          />

          {/* Expiration Date section */}
          <Box mt={4} />
          <Controller
            control={control}
            key="expireDate"
            name="expireDate"
            rules={{
              required: formatMessage({
                id: 'profile.personalAccessToken.add.dialog.expiration.required',
              }),
            }}
            render={({ field, field: { value }, fieldState: { error } }) => (
              <TextField
                size="small"
                id="expireDate"
                disabled={isPending}
                select
                fullWidth
                margin="dense"
                variant="outlined"
                label={
                  <Translate text="profile.personalAccessToken.add.dialog.expiration" />
                }
                error={!!error}
                helperText={
                  <Translate
                    text="profile.personalAccessToken.add.dialog.expiration.helper"
                    values={{ expDate: computeExpirationDate(value) }}
                  />
                }
                {...field}
              >
                {ALL_EXPIRATION.map((expirationDate) => (
                  <MenuItem key={expirationDate} value={expirationDate}>
                    <Translate text={getExpirationDate(expirationDate)} />
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          {/* Account roles section */}
          <Box mt={4}>
            <Translate text="admin.accounts.table.edit.roles" />
          </Box>
          <Controller
            control={control}
            key="roles"
            name="roles"
            rules={{
              required: formatMessage({
                id: 'profile.personalAccessToken.add.dialog.roles.required',
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                size="small"
                id="roles"
                disabled={isPending}
                select
                SelectProps={{
                  multiple: true,
                  renderValue: (options) => (
                    <div className={classes.chips}>
                      {options.map((role) => (
                        <Chip
                          key={role}
                          label={
                            <Translate
                              text={
                                isEqual(groupName, GroupType.CO2) ||
                                isEqual(groupName, GroupType.HINTERLAND)
                                  ? getCo2RoleName(role)
                                  : getRoleName(role)
                              }
                            />
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  ),
                }}
                fullWidth
                margin="dense"
                variant="outlined"
                label={<Translate text="admin.accounts.add.dialog.roles" />}
                error={!!error}
                helperText={error?.message}
                {...field}
              >
                {Object.entries(initiallyRoles)
                  .filter(([, _checked]) => _checked)
                  .map(([role]) => (
                    <MenuItem key={role} value={role}>
                      <Translate
                        text={
                          isEqual(groupName, GroupType.CO2) ||
                          isEqual(groupName, GroupType.HINTERLAND)
                            ? getCo2RoleName(role)
                            : getRoleName(role)
                        }
                      />
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </DialogContent>

        {/* Controls section */}
        <DialogActions>
          <Box mt={2} p={2}>
            <Button onClick={onClose} color="secondary" disabled={isPending}>
              <Translate text="action.cancel" />
            </Button>

            <Button
              color="primary"
              disabled={isPending}
              type="submit"
              variant="contained"
            >
              <Translate text="action.add" />
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};
