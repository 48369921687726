import { useState } from 'react';
import { Box, Paper, styled } from '@mui/material';
import { isEqual } from 'lodash-es';
import { CenterSpinner } from 'components/CenterSpinner';
import { TestCodeLabel } from 'components/test';
import { GroupType } from 'constants/GroupType';
import { EMPTY_STRING } from 'constants/semanticConstants';
import useCheckInState from '../hooks/useCheckInState';
import { ResultError } from './ResultError';
import { ResultForm } from './ResultForm';
import { ResultInfo } from './ResultInfo';
import { ResultSendHealthAuthorityNotification } from './ResultSendHealthAuthorityNotification';
import { ResultSuppressNotification } from './ResultSuppressNotification';

const Layout = styled(Paper)`
  padding: 16px;
  margin-top: 16px;
`;

const TestCodeLabelPos = styled(TestCodeLabel)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EnterResult = ({
  onFinished,
  onRestartClick,
  scannedValue,
  isOldURLFormat,
  groupName,
}) => {
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [sendHealthAuthorityResult, setSendHealthAuthorityResult] =
    useState(true);
  const [error, setError] = useState(false);

  const onSuccess = ({ flags }) => {
    if (flags) {
      setNotifyCustomer(!flags?.suppress_customer_notification?.checked);
      setSendHealthAuthorityResult(
        !flags?.suppress_health_authority_notification?.checked,
      );
    } else if (
      isEqual(groupName, GroupType.CO2) ||
      isEqual(groupName, GroupType.PLASTIC) ||
      isEqual(groupName, GroupType.HINTERLAND)
    ) {
      setNotifyCustomer(true);
      setSendHealthAuthorityResult(true);
    } else {
      setError(true);
    }
  };

  const onError = () => setError(true);

  const { isLoading: isPending, data: checkInState } = useCheckInState({
    isOldURLFormat,
    scannedValue,
    onSuccess,
    onError,
  });

  const onResultSelected = (result) =>
    onFinished({
      result,
      testCode: checkInState?.test_code,
      notifyCustomer,
      sendHealthAuthorityResult,
    });

  if (isPending) {
    return <CenterSpinner />;
  }

  if (error) {
    return (
      <ResultError
        errorText={
          isOldURLFormat
            ? 'test.result.check_in.error'
            : 'test.result.scan_testcode.error'
        }
        onRestartClick={onRestartClick}
      />
    );
  }

  return (
    <Box>
      <ResultInfo data={checkInState} />

      <Layout>
        <TestCodeLabelPos testCode={checkInState?.test_code} />
      </Layout>
      {isEqual(groupName, GroupType.CO2) ||
      isEqual(groupName, GroupType.PLASTIC) ||
      isEqual(groupName, GroupType.HINTERLAND) ? (
        EMPTY_STRING
      ) : (
        <>
          <ResultSuppressNotification
            data={checkInState}
            notifyCustomer={notifyCustomer}
            onNotifyCustomerChanged={setNotifyCustomer}
          />
          <ResultSendHealthAuthorityNotification
            data={checkInState}
            sendHealthAuthorityResult={sendHealthAuthorityResult}
            onSendHealthAuthorityResultChanged={setSendHealthAuthorityResult}
          />
        </>
      )}
      <ResultForm
        onResultSelected={onResultSelected}
        options={checkInState?.result_options}
      />
    </Box>
  );
};
